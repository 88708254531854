import type { Breakpoints, Colors, Fonts } from 'types/theme'

export const breakpoints: Breakpoints = {
  base: 0,
  xs: 400,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1440,
  '3xl': 1600,
}

export const colors: Colors = {
  primary: '#AA8C72',
  primary100: '#F4F0ED',
  primary50: '#96785E',
  primary10: '#C6B09E',
  gray100: '#3A394C',
  gray85: '#E9E9E9',
  gray80: '#50505E',
  gray10: '#D9D9D9',
  alert100: '#9F1B1F',
  alert50: '#DE1C22',
  textPrimary: '#3A394C',
  stroke: '#89827A',

  available: ' #3BA440',
  reserved: '#FCB21F',
  sold: '#E81D25',

  white: '#FFFFFF',
  black: '#000000',
}

export const fonts: Fonts = {
  size: '16px',
}

const theme = {
  breakpoints,
  colors,
  fonts,
}

export default theme
